// import React, { useState } from 'react'
// import './mainblock.css'
// import { PlayIcon } from '../../../Assets/Icons/MainBlock'
// import {
//   Logo1,
//   Logo2,
//   Logo3,
//   Logo4,
//   Logo5,
// } from '../../../Assets/Images/MainBlock'
// import PopUp from '../../../Components/PopUp/PopUp'
// import { Link } from 'react-scroll'
//
// const MainBlock = () => {
//   const [showPopup, setShowPopup] = useState(false)
//
//   const handleShow = () => setShowPopup(true)
//   const handleClose = () => setShowPopup(false)
//   return (
//     <div className="main_block" id={'main'}>
//       <div className="customContainer">
//         <div className="main_content">
//           <div className="main_content_top">
//             <h1 className="mainblock_title">
//               <span className="mainblock_title_span">Сучасні</span>{' '}
//               інтернет-магазини{' '}
//               <span className="mainblock_title_span">з системами</span> CRM{' '}
//               <span className="mainblock_title_span">та</span> ERP
//             </h1>
//             <p className="mainblock_title_text">
//               Комплексні рішення для якісного ведення вашого товарного бізнесу.
//             </p>
//             <div className="main_block_buttons">
//               <button onClick={handleShow} className="main_btn_try">
//                 <img src={PlayIcon} alt="PlayIcon" /> Спробувати
//               </button>
//               <Link to="offer" smooth={true} duration={200}>
//                 <button className="main_btn_more">Дізнатися більше</button>
//               </Link>
//             </div>
//           </div>
//           <div>
//             <p className="main_text_uplogo">
//               Приєднуйтесь до компаній, які вже користуються aima.
//             </p>
//             <div className="main_logo_block">
//               <img className="main_logo_img" src={Logo1} alt="Logo1" />{' '}
//               <img className="main_logo_img" src={Logo2} alt="Logo2" />{' '}
//               <img className="main_logo_img" src={Logo3} alt="Logo3" />{' '}
//               <img className="main_logo_img" src={Logo4} alt="Logo4" />{' '}
//               <img className="main_logo_img" src={Logo5} alt="Logo5" />{' '}
//             </div>
//           </div>
//         </div>
//       </div>
//       <PopUp show={showPopup} handleClose={handleClose} title="Popup Title">
//         <p>This is the content of the popup.</p>
//       </PopUp>
//       тут має бути гіфка
//     </div>
//   )
// }
//
// export default MainBlock

import React, { useState } from 'react'
import './mainblock.css'
import {
  // GifImage,
  Logo1,
  Logo2,
  Logo3,
  Logo4,
  Logo5,
} from '../../../Assets/Images/MainBlock'
import PopUp from '../../../Components/PopUp/PopUp'
import { Link } from 'react-scroll'
import styles from '../../../Components/TextItem/TextItem.module.css'

const MainBlock = () => {
  const [showPopup, setShowPopup] = useState(false)

  const handleShow = () => setShowPopup(true)
  const handleClose = () => setShowPopup(false)
  return (
    <div className="main_block" id={'main'}>
      <div className="customContainer">
        <div className="main_content">
          <div className="main_content_top">
            <h1 className="mainblock_title">
              <span className="mainblock_title_span">Сучасні</span>{' '}
              інтернет-магазини{' '}
              <span className="mainblock_title_span">з системами</span> CRM{' '}
              <span className="mainblock_title_span">та</span> ERP
            </h1>
            <p className="mainblock_title_text">
              Комплексні рішення для якісного ведення  вашого товарного бізнесу.
            </p>
            <div className="main_block_buttons">
              <button onClick={handleShow} className="main_btn_try">
                <iframe
                  title="Lottie animation"
                  className={styles.icon}
                  src="https://lottie.host/embed/f3bcfdd8-85de-43e2-ba88-223e558b8fe6/loLsyG2Vxx.json"
                ></iframe>
                {/*<img src={PlayIcon} alt="PlayIcon" /> */}
                Спробувати
              </button>
              <Link to="offer" smooth={true} duration={200}>
                <button className="main_btn_more">Дізнатися більше</button>
              </Link>
            </div>
          </div>
          <div>
            <p className="main_text_uplogo">
              Приєднуйтесь до компаній, які вже користуються aima.
            </p>
            <div className="main_logo_block">
              <img className="main_logo_img" src={Logo1} alt="Logo1" />{' '}
              <img className="main_logo_img" src={Logo2} alt="Logo2" />{' '}
              <img className="main_logo_img" src={Logo3} alt="Logo3" />{' '}
              <img className="main_logo_img" src={Logo4} alt="Logo4" />{' '}
              <img className="main_logo_img" src={Logo5} alt="Logo5" />{' '}
            </div>
          </div>
        </div>
      </div>
      {/*<div className="gif_container">*/}
      {/*  <img src={GifImage} alt="GIF" className="gif_image" />*/}
      {/*</div>*/}
      <PopUp show={showPopup} handleClose={handleClose} title="Popup Title">
        <p>This is the content of the popup.</p>
      </PopUp>
    </div>
  )
}

export default MainBlock
