import React from 'react'
import styles from '../Advantages/Advantage.module.css'
import style from './HowItWorks.module.css'
const HowItWorks = () => {
  return (
    <div className={`{${'customContainer'} ${style.container}`} id={'howworks'}>
      <h2 className={styles.desc}>Як це працює</h2>
      <p className={styles.title}>
        Зроби свій бізнес ефективнішим завдяки aima
      </p>
      <div className={style.image} />
    </div>
  )
}

export default HowItWorks
