import React from 'react'
import TextItem from '../../../Components/TextItem/TextItem'
import {
  Linas,
  WebsiteIcon,
  WebsiteIcon1,
  WebsiteIcon2,
  WebsiteIcon3,
  WebsiteImg,
  WebsiteImgIcon,
} from '../../../Assets/Icons/Website/Index'
import styles from './Website.module.css'
import ReviewCard from '../../../Components/ReviewCard/ReviewCard'
const Website = () => {
  const content = {
    subtitle: 'Інтернет-магазин',
    subtitleImage: WebsiteIcon,
    title: 'Виставляй всі товари у власний інтернет-магазин',
    description:
      'Легко додавай товари до інтернет-магазину за допомогою зручної системи управління:',
    benefits: [
      'Формуй відповідні категорії товарів',
      'Гнучко налаштовуй варіації товарів',
      'Контролюй акційні пропозиції',
    ],
    servicesText: 'Просто підключай додаткові сервіси до aima:',
    services: [
      { name: 'Google Shopping', logo: WebsiteIcon1 },
      { name: 'LIQPAY', logo: WebsiteIcon2 },
      { name: 'plata by mono', logo: WebsiteIcon3 },
    ],
    buttonText: 'Спробувати aima',
    href: '#form',
  }
  const review = {
    image: Linas,
    name: 'Лінас Маліска',
    position: 'Власник компанії "Pontic Trading"',
    stars: 5,
    text: 'Дуже задоволений роботою з компанією aima. Керувати магазином стало набагато простіше. Інтерфейс зручний, все працює швидко, і покупцям легко знаходити товари. Оформлення замовлень для клієнтів просте і зрозуміле, що робить їх задоволеними.',
  }

  return (
    <div className={styles.hidden} id={'website'}>
      <div className={`${styles.container} ${'customContainer'}`}>
        <TextItem content={content} />
        <img src={WebsiteImg} alt="imageIcon" className={styles.image} />
      </div>
      <div className={`${styles.wrap} ${'customContainer'}`}>
        <ReviewCard review={review} />
        <img
          src={WebsiteImgIcon}
          alt="imageIcon"
          className={styles.imageIcon}
        />
      </div>
    </div>
  )
}

export default Website
