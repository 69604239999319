import React from 'react'
import './faq.css'
import { Accordion } from 'react-bootstrap'

const Faq = () => {
  return (
    <div className="faq_block" id={'faq'}>
      <div className="customContainer">
        <div className="faq_title_block">
          <div>
            <h2 className="faq_title">Залишилися запитання?</h2>
          </div>
        </div>
        <div className="customContainerFaq">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <p className="faq_text_title">
                  Скільки часу займає створення інтернет-магазину "під ключ"?
                </p>
              </Accordion.Header>
              <Accordion.Body>
                Створення автоматизованого інтернет-магазину займає від 20 днів.
                Час реалізації залежить від швидкості надання відповідей від
                клієнта та кількості інтеграцій.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <p className="faq_text_title">
                  Чи можу я самостійно керувати інтернет-магазином після
                  запуску?
                </p>
              </Accordion.Header>
              <Accordion.Body>
                Так, наші інтернет-магазини розроблені для зручного управління.
                Після запуску ви зможете самостійно оновлювати товари, стежити
                за замовленнями, аналізувати продажі та керувати акціями та
                іншими можливостями без необхідності технічної підтримки.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <p className="faq_text_title">
                  Які інтеграції можливі з іншими сервісами?
                </p>
              </Accordion.Header>
              <Accordion.Body>
                Ми підтримуємо інтеграції з популярними платформами, такими як
                Google Analytics, Google Tag Manager, Facebook Pixel, платіжні
                шлюзи та системи обліку. Це дозволить автоматизувати процеси та
                розширити функціональні можливості вашого інтернет-магазину.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <p className="faq_text_title">
                  Чи забезпечується безпека даних в інтернет-магазині?
                </p>
              </Accordion.Header>
              <Accordion.Body>
                Так, безпека даних — наш пріоритет. Ми використовуємо сучасні
                методи шифрування, регулярні оновлення системи безпеки та
                багаторівневий контроль доступу, щоб захистити дані клієнтів і
                вашу комерційну інформацію.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <p className="faq_text_title">
                  Як оптимізувати сайт інтернет-магазину для SEO?
                </p>
              </Accordion.Header>
              <Accordion.Body>
                Ми надаємо базову SEO-оптимізацію на етапі розробки, включаючи
                додавання мета-тегів, створення зрозумілих URL-адрес, додавання
                альт-тексту до зображень та налаштування швидкості завантаження
                сторінок для покращення видимості у пошукових системах.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <p className="faq_text_title">
                  Як підтримувати та оновлювати інтернет-магазин після запуску?
                </p>
              </Accordion.Header>
              <Accordion.Body>
                Після запуску ми надаємо технічну підтримку спеціалістів та
                регулярні оновлення, щоб ваш інтернет-магазин залишався
                безпечним і функціональним. Ви зможете самостійно оновлювати
                контент і додавати нові товари, а також скористатися нашими
                послугами підтримки для подальшого розвитку та інтеграції нових
                функцій.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  )
}
export default Faq
